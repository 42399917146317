@import '../theme-bootstrap';

.elc-regimen-builder-block {
  margin: 20px auto;
  &--header {
    @include text-title--large;
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $cr19-border-grey;
    font-family: $helvetica-light-font-family;
    color: $cr19-text;
  }
  &__product {
    display: flex;
    @media #{$cr19-large-up} {
      flex-direction: column;
    }
    &-container {
      position: relative;
      @media #{$cr19-large-up} {
        @include container-padding-rules;
        max-width: 1920px;
        min-height: 500px;
        margin: 0 auto;
        width: 100%;
      }
    }
    &-items-carousel {
      &:not(.slick-initialized) {
        @include container-padding-rules;
        @media #{$cr19-large-up} {
          padding: 0 52px;
          margin: 0;
          overflow: visible;
          white-space: nowrap;
        }
        @media #{$cr19-xlarge-up} {
          padding: 0 60px;
        }
      }
      @media #{$cr19-large-up} {
        box-sizing: border-box;
        display: block;
        margin: 0 -12px;
        animation: fade-in 0.25s ease-in-out forwards;
        height: auto;
        overflow-y: visible;
        pointer-events: all;
        position: relative;
        touch-action: pan-y;
        user-select: none;
        visibility: visible;
      }
      .slick-prev,
      .slick-next {
        &::before {
          @media #{$cr19-large-up} {
            background: url('#{$base-theme-path}img/icons/src/arrow-16.svg') no-repeat center;
            background-size: cover;
            color: currentColor;
            display: inline-block;
            position: absolute;
            top: 33%;
          }
        }
      }
      .slick-prev {
        &::before {
          @media #{$cr19-large-up} {
            #{$ldirection}: -10px;
            transform: rotate(180deg);
          }
        }
      }
      .slick-next {
        &::before {
          @media #{$cr19-large-up} {
            #{$rdirection}: -10px;
          }
        }
        &.slick-disabled::before {
          opacity: 0.25;
        }
      }
      .slick-list {
        @include container-padding-rules;
        overflow: visible;
        @media #{$cr19-xlarge-up} {
          display: block;
          margin: 0;
          min-height: 1px;
          min-width: 1px;
          padding: 0;
          overflow: hidden;
          position: relative;
          transform: translate3d(0, 0, 0);
        }
      }
    }
    &-item {
      position: relative;
      width: 100%;
      padding-bottom: 60px;
      @media #{$cr19-large-up} {
        display: inline-flex;
        flex-direction: column;
        width: 25%;
        padding: 0 12px;
        white-space: normal;
      }
      &:last-child {
        padding-bottom: 0;
        &::after {
          display: none;
        }
      }
      &::after {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        #{$ldirection}: 50%;
        transform: translateX(-50%);
        width: 16px;
        height: 60px;
        background: url('#{$base-theme-path}img/icons/src/plus-16.svg') no-repeat center;
        @media #{$cr19-large-up} {
          display: none;
        }
      }
      &--headline {
        @include text-title--small;
        margin-bottom: 5px;
        font-family: $helvetica-regular-font-family;
        color: $cr19-text;
      }
      &--content {
        @include text-body-text;
        margin-bottom: 20px;
        color: $cr19-text-inactive;
      }
    }
    &-link {
      width: 100%;
    }
    &-checkbox {
      &-container {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        width: 24px;
        height: 24px;
        @media #{$cr19-large-up} {
          top: 15px;
          #{$ldirection}: auto;
          #{$rdirection}: 15px;
          width: 20px;
          height: 20px;
        }
      }
      &-input {
        display: none;
        &:checked ~ .elc-regimen-builder-block__product-checkbox-icon {
          background: url('#{$base-theme-path}img/icons/src/checkbox-selected-dark-v2.svg') no-repeat center;
        }
        &:disabled ~ .elc-regimen-builder-block__product-checkbox-icon {
          background: url('#{$base-theme-path}img/icons/src/x-10.svg') no-repeat center;
          cursor: not-allowed;
        }
      }
      &-icon {
        display: block;
        width: 100%;
        height: 100%;
        background: none;
        border: 1px solid $color-black;
      }
    }
    &-image {
      display: block;
      width: 100%;
      aspect-ratio: 4/5;
      @media #{$cr19-large-up} {
        width: 75%;
        margin: auto;
      }
      &-container {
        position: relative;
        display: inline-block;
        width: 100px;
        margin-#{$rdirection}: 20px;
        background-color: $color-light-white;
        @media #{$cr19-large-up} {
          display: block;
          width: 100%;
          margin: 0 0 10px;
        }
        &::after {
          @media #{$cr19-large-up} {
            content: '';
            position: absolute;
            display: block;
            top: 50%;
            #{$rdirection}: -20px;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            background: url('#{$base-theme-path}img/icons/src/plus-16.svg') no-repeat center;
          }
          .elc-regimen-builder-block__product-item:last-child & {
            display: none;
          }
        }
      }
    }
    &-info {
      display: flex;
      flex-direction: column;
      width: calc(100% - 120px);
      float: $rdirection;
      @media #{$cr19-large-up} {
        width: 100%;
        float: none;
      }
    }
    &-sku-select {
      margin-bottom: 10px;
      @media #{$cr19-large-up} {
        min-height: 45px;
        margin-bottom: 20px;
      }
      &-sizes-dropdown,
      &-shades-dropdown {
        @include text-body-text;
        @include dropdown-base(100%);
        width: 100%;
        height: 45px;
        padding: 10px 15px;
        border: 1px solid $color-black;
      }
      &-shades-color {
        @include swap_direction(margin, 0 5px 3px 0);
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;
      }
      &-size-label,
      &-shade-label {
        @include text-body-text;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: 45px;
        padding: 0 15px;
        border: 1px solid $color-black;
        @media #{$cr19-xlarge-up} {
          font-size: 1rem;
        }
        .elc-regimen-builder-block__product-sku-select-shades-color {
          @include swap_direction(margin, 0 10px 0 0);
        }
      }
      .sbSelector {
        @include text-body-text;
        @include swap_direction(padding, 0 43px 0 15px);
        /* stylelint-disable-next-line value-no-vendor-prefix */
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
      }
      .sbHolder {
        .sbToggle {
          top: 0;
          #{$rdirection}: 0;
        }
        .sbOptions {
          // To override the inline max-height added by selectbox.js
          /* stylelint-disable-next-line declaration-no-important */
          max-height: 184px !important;
          a {
            /* stylelint-disable-next-line value-no-vendor-prefix */
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
          }
        }
      }
    }
    &-title {
      @include text-body-text;
      margin-bottom: 10px;
      font-family: $helvetica-bold-font-family;
      color: $cr19-text;
      order: -1;
      @media #{$cr19-large-up} {
        /* stylelint-disable-next-line value-no-vendor-prefix */
        display: -webkit-box;
        margin-bottom: 15px;
        order: unset;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
    &-price {
      .price {
        @include text-body-text;
        display: inline-block;
        font-family: $helvetica-bold-font-family;
        color: $cr19-text;
      }
      .old-price {
        @include text-body-text;
        display: inline-block;
        margin-#{$ldirection}: 10px;
        color: $cr19-text-inactive;
        text-decoration: line-through;
        &.hidden {
          display: none;
        }
      }
    }
    &-price-per-unit {
      @include text-body-text;
      display: inline-block;
      color: $cr19-text-inactive;
      &::before {
        content: '|';
        display: inline-block;
        margin: 0 10px;
        color: $cr19-border-light;
      }
      &.hidden {
        display: none;
      }
    }
    &-rating {
      display: flex;
      align-items: center;
      &-average,
      &-stars {
        background: url('#{$base-theme-path}img/icons/src/star.svg') repeat-x;
        background-size: 14px;
        height: 14px;
      }
      &-average {
        @include swap_direction(margin, 0 4px 10px 0);
        filter: brightness(0.85);
        width: 70px;
      }
      &-stars {
        width: 0;
        filter: brightness(0);
      }
      &-total {
        @include text-body-text--small;
        margin-bottom: 10px;
        color: $cr19-text-inactive;
      }
    }
    &-inventory-status {
      @include text-body-text--small;
      margin-top: 10px;
      color: $cr19-text-inactive;
    }
  }
  &-checkout {
    visibility: hidden;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $cr19-border-grey;
    text-align: center;
    &--total-price {
      @include text-body-text;
      color: $cr19-text;
      margin-bottom: 10px;
      .mantle-custom-text {
        display: inline-block;
      }
    }
    .elc-regimen-block-initialized & {
      visibility: visible;
    }
  }
  .product-shade-picker__show-all-shades-panel {
    display: none;
  }
  &[data-products-count='2'],
  &[data-products-count='3'] {
    .elc-regimen-builder-block-checkout {
      @media #{$cr19-large-up} {
        margin: 0 0 100px auto;
        padding: 0;
        border: none;
      }
      &-container {
        @media #{$cr19-large-up} {
          @include swap_direction(padding, 0 52px 0 0);
          position: absolute;
          display: flex;
          top: 0;
          #{$rdirection}: 0;
          height: 100%;
          align-items: center;
        }
        @media #{$cr19-xlarge-up} {
          @include swap_direction(padding, 0 60px 0 0);
        }
      }
    }
  }
  &[data-products-count='2'] {
    .elc-regimen-builder-block-checkout-container {
      @media #{$cr19-large-up} {
        width: 50%;
      }
    }
  }
  &[data-products-count='3'] {
    .elc-regimen-builder-block-checkout-container {
      @media #{$cr19-large-up} {
        width: calc((100% + 104px) / 4);
      }
      @media #{$cr19-xlarge-up} {
        width: calc((100% + 120px) / 4);
      }
    }
  }
  &-hide-plus-icons-mobile {
    .elc-regimen-builder-block__product-item {
      &::after {
        display: none;
      }
    }
  }
  &-hide-plus-icons-desktop {
    .elc-regimen-builder-block__product-image-container {
      &::after {
        @media #{$cr19-large-up} {
          display: none;
        }
      }
    }
  }
}

.spp-product-layout__content-item {
  &:has(.elc-regimen-builder-block) {
    margin: 0 -20px;
    @media #{$cr19-xsmall-up} {
      margin: 0 -28px;
    }
    @media #{$cr19-small-up} {
      margin: 0 -36px;
    }
    @media #{$cr19-medium-up} {
      margin: 0 -44px;
    }
    @media #{$cr19-large-up} {
      margin: 0 -64px;
    }
    @media #{$cr19-xlarge-up} {
      margin: 0 -72px;
    }
  }
}
